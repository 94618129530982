import {scrolEvents} from './default';
import {index} from './index';
import {vues} from './vue';

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
	
	scrolEvents(controller , scene);
    index();
    vues();
});