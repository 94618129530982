import Swiper from 'swiper';

export function index(){
    if(document.getElementById('slide')){
        const swiper = new Swiper('#slide', {
            effect: 'fade',
            spaceBetween: 0,
            centeredSlides: true,
            speed: 1500,
            loop: true,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
            }
        }); 
    }
}